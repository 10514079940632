<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
import { firestore } from './plugins/fireinit';

export default {
  name: 'App',

  components: {},

  data: () => ({
    currentUser: null,
  }),
  created() {
    firestore.enablePersistence().catch((err) => {
      console.log(err);
      if (err.code == 'failed-precondition') {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
      } else if (err.code == 'unimplemented') {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
      }
    });
  },
};
</script>

<style lang="scss">
table.vgt-table {
  font-size: 13px !important;
}
html {
  font-size: 14px !important;
}
</style>
