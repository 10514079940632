import {
  fileExtensions,
  imageExtensions,
} from '../services/encryption_service.js';
import firebase from 'firebase/app';

export const getMediaType = (url) => {
  if (url == null || url == '') {
    return 'file';
  }
  let ext = getMediaExtension(url);
  // console.log(ext);
  var fe = fileExtensions;
  var ie = imageExtensions;
  if (fe.indexOf(ext?.toLowerCase()) > -1) {
    return 'file';
  } else if (ie.indexOf(ext?.toLowerCase()) > -1) {
    return 'image';
  } else {
    return 'video';
  }
};

export const getMediaName = (url) => {
  if (url == null || url == '') {
    return '';
  }
  var httpsReference = firebase.storage().refFromURL(url);
  // console.log(httpsReference);
  return httpsReference.name;
};

export const getMediaExtension = (url) => {
  if (url == null || url == '') {
    return '';
  }
  let fileName = getMediaName(url);
  // console.log(fileName);
  return fileName.split('.')[1];
};
