import Vue from 'vue';
import VueRouter from 'vue-router';
import Dashboard from '../views/Dashboard.vue';
import firebase from 'firebase/app';
import 'firebase/auth';

Vue.use(VueRouter);
const routes = [
  {
    // path: '/admin',
    path: '/',
    component: Dashboard,
    children: [
      {
        path: '',
        name: 'UserCollection',
        component: () =>
          import(
            /* webpackChunkName: "UserCollection" */ '../views/UserCollection.vue'
          ),
      },
      {
        path: '/doctor',
        name: 'Doctor',
        component: () => import('../views/Doctor.vue'),
      },
      {
        path: '/add-users',
        name: 'AddUsers',
        component: () => import('../views/AddUsers.vue'),
      },
      {
        path: '/add-doctor',
        name: 'AddDoctor',
        component: () => import('../views/AddDoctor.vue'),
      },
      {
        path: '/edit-doctor/:id',
        name: 'AddDoctorById',
        component: () => import('../views/AddDoctor.vue'),
      },
      {
        path: '/company',
        name: 'Company',
        component: () => import('../views/Company.vue'),
      },
      {
        path: '/add-company',
        name: 'AddCompany',
        component: () => import('../views/AddCompany.vue'),
      },
      {
        path: '/edit-company/:id',
        name: 'AddCompanyById',
        component: () => import('../views/AddCompany.vue'),
      },
      {
        path: '/company-type',
        name: 'CompanyType',
        component: () => import('../views/CompanyType.vue'),
      },
      {
        path: '/add-company-type',
        name: 'AddCompanyType',
        component: () => import('../views/AddCompanyType.vue'),
      },
      {
        path: '/edit-company-type/:id',
        name: 'AddCompanyTypeById',
        component: () => import('../views/AddCompanyType.vue'),
      },
      {
        path: '/user/:uid',
        name: 'User',
        component: () =>
          import(/* webpackChunkName: "User" */ '../views/User.vue'),
        children: [
          {
            path: ':collection/:doc',
            name: 'DocDetails',
            component: () =>
              import(
                /* webpackChunkName: "DocDetails" */ '../views/DocDetails.vue'
              ),
          },
        ],
      },
      {
        path: '/create-card',
        name: 'CreateCard',
        component: () => import('../views/CreateCard.vue'),
      },
      {
        path: '/edit-card/:id',
        name: 'CreateCardById',
        component: () => import('../views/CreateCard.vue'),
      },
      {
        path: '/all-cards-template',
        name: 'AllCardsTemplate',
        component: () => import('../views/AllCardsTemplate.vue'),
      },
      {
        path: '/create-cards-template',
        name: 'AllCardsTemplate',
        component: () => import('../views/AllCardsTemplate.vue'),
      },
    ],
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/login',
    name: 'Auth',
    component: () => import(/* webpackChunkName: "Auth" */ '../views/Auth.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.authRequired)) {
    if (firebase.auth().currentUser) {
      // console.log(from.query?.redirectTo, to.path);
      if (from.query?.redirectTo && from.query?.redirectTo !== to.path) {
        next({
          path: from.query?.redirectTo || '/',
        });
      } else next();
    } else {
      next({
        path: '/login',
        query: {
          redirectTo: to?.path || '/',
        },
      });
    }
  } else {
    next();
  }
});

export default router;
