import CryptoJS from 'crypto-js';

export function getModifiedKeyFrom(initialKey) {
  var paddingNeeded = 32 - initialKey.length;

  return paddingNeeded == 0
    ? initialKey
    : initialKey + initialKey.substring(0, paddingNeeded);
}
export function decrypt(keyStr, ivStr, encrptData, isJson = true) {
  let key;
  let iv;

  if (keyStr) {
    key = CryptoJS.enc.Utf8.parse(getModifiedKeyFrom(keyStr));

    iv = CryptoJS.enc.Utf8.parse(getModifiedIvPasswordFrom(ivStr));
  }

  var decrypt = CryptoJS.AES.decrypt(encrptData, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  var decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
  if (!isJson) {
    return decryptedStr;
  }
  return JSON.parse(decryptedStr);
}
export function getModifiedIvPasswordFrom(initialIV) {
  initialIV = initialIV.substring(0, Math.min(10, initialIV.length));
  var paddingNeeded = 16 - initialIV.length;
  const developerKey = 'henry@cardo-app.com';
  return initialIV + developerKey.substring(0, paddingNeeded);
}
export function encrypt(keyStr, ivStr, data) {
  let key;
  let iv;
  const dataStr = JSON.stringify(data);

  if (keyStr) {
    key = CryptoJS.enc.Utf8.parse(getModifiedKeyFrom(keyStr));

    iv = CryptoJS.enc.Utf8.parse(getModifiedIvPasswordFrom(ivStr));
  }

  var encrypt = CryptoJS.AES.encrypt(dataStr, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  return encrypt.toString();
}

export const imageExtensions = ['jpg', 'jpeg', 'png', 'heif', 'gif'];

export const fileExtensions = [
  'pdf',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'pptx',
  'txt',
  'GSHEET',
  'csv',
  'ods',
  'xlsm',
  'pps',
  'odp',
  'key',
  'wpd',
  'tex',
  'rtf',
  'odt',
  'gsheet',
];
