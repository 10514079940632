import Vue from 'vue';
import VueRouter from 'vue-router';
import 'firebase/auth';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
  },
  {
    path: '/download-visicardia',
    name: 'DownloadVisicardia',
    component: () =>
      import(
        /* webpackChunkName: "DownloadVisicardia" */ '../views/DownloadVisicardia.vue'
      ),
  },
  {
    path: '/about',
    name: 'About',
    component: () =>
      import(/* webpackChunkName: "About" */ '../views/About.vue'),
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: () =>
      import(/* webpackChunkName: "Feedback" */ '../views/Feedback.vue'),
  },
  {
    path: '/help',
    name: 'Help',
    component: () => import(/* webpackChunkName: "Help" */ '../views/Help.vue'),
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () =>
      import(/* webpackChunkName: "Privacy" */ '../views/Privacy.vue'),
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () =>
      import(/* webpackChunkName: "Terms" */ '../views/Terms.vue'),
  },
 
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
});

export default router;
