import { firestore, storage } from '../plugins/fireinit';
import { decrypt } from '../services/encryption_service';
import { getMediaType } from '../services/utils';
import { db } from '../services/db';

export default {
  namespaced: true,
  state() {
    return {
      findChatText: '',
      searchUser: '',
      chatLoader: false,
      messages: [],
      chatroomLoader: false,
      chatrooms: [],
      sessionUID: '',
      selectedRoom: null,
      isForward: false,
      searchToggle: false,
      isDeleteChat: false,
      selectedChatMessage: null,
      isLastChatIndex: false,
      snackbar: false,
      snackBartext: '',
      showSupportAddDialog: false,
      isBlockDialog: false,
      chatDetailSettings: false,
      previousChat: null,
      supportChat: null,
      results: [],
      searchLoader: false,
      mediaIndex: 0,
      mediaChats: [],
    };
  },
  mutations: {
    setSearchText(state, text) {
      state.findChatText = text;
    },
    setCurrentRoom(state, room) {
      state.selectedRoom = room;
    },
    setMessages(state, msg) {
      state.messages = msg;
    },
    setChatLoader(state, flag) {
      state.chatLoader = flag;
    },
    setSearchUser(state, search) {
      state.searchUser = search;
    },
    setChatrooms(state, rooms) {
      const uid = state.sessionUID;
      const chatrooms = [];

      (rooms || []).forEach(async (room) => {
        const partnerId = room[uid]['partnerId'];
        const profile = room[partnerId]['profile'];
        if (profile?.isSupport === true) {
          state.supportChat = room;
        } else {
          chatrooms.push(room);
        }
      });

      if (uid) {
        chatrooms.sort((a, b) => {
          const bDate = b['lastChat']
            ? b['lastChat'][uid]['date'] ?? 0
            : b['last_chat_date'] ?? 0;
          const aDate = a['lastChat']
            ? a['lastChat'][uid]['date'] ?? 0
            : a['last_chat_date'] ?? 0;
          return bDate - aDate;
        });
      }
      state.chatrooms = chatrooms;
    },
    setSessionUID(state, uid) {
      state.sessionUID = uid;
    },
    setChatroomLoader(state, flag) {
      state.chatroomLoader = flag;
    },
    setIsForward(state, flag) {
      state.isForward = flag;
    },
    setSearchToggle(state, flag) {
      state.searchToggle = flag;
    },
    setIsDeleteChat(state, flag) {
      state.isDeleteChat = flag;
      state.selectedChatMessage = null;
      state.isLastChatIndex = false;
    },
    setSelectedChatMessage(state, chat) {
      state.selectedChatMessage = chat;
    },
    setSnackbar(state, flag) {
      state.snackbar = flag;
    },
    setSnackBartext(state, text) {
      state.snackbar = true;
      state.snackBartext = text;
    },
    setShowSupportAddDialog(state, flag) {
      state.showSupportAddDialog = flag;
    },
    setIsBlockDialog(state, flag) {
      state.isBlockDialog = flag;
    },
    setChatDetailSettings(state, flag) {
      state.chatDetailSettings = flag;
    },
    setPreviousChat(state, chat) {
      state.previousChat = chat;
    },
    setSupportChat(state, chatroom) {
      state.supportChat = chatroom;
    },
    setSearchResults(state, results) {
      state.results = results;
    },
    setSearchLoader(state, flag) {
      state.searchLoader = flag;
    },
    setMediaIndex(state, index) {
      state.selectedChatMessage = state.mediaChats[index];
      state.mediaIndex = index;
    },
    setMediaChats(state, mediaChats) {
      state.mediaChats = mediaChats;
    },
  },
  actions: {
    async searchChatroomMessage({ commit, state }, text) {
      let result = [];
      if (text.length > 1) {
        commit('setSearchLoader', true);
        await db.chats
          .where('message')
          .startsWithAnyOfIgnoreCase([text])
          .each((message) => {
            const chatroom = state.chatrooms.filter(
              (c) => c.id == message.chatRoomId
            )[0];
            result.push({
              chatroom,
              message,
            });
          });
      }
      commit('setSearchResults', result);
      commit('setSearchLoader', false);
      return result;
    },
    async fetchChatRooms({ state, commit }, uid) {
      const intialKeys = await db.chatrooms.orderBy('id').keys();
      if (intialKeys.length == 0) {
        commit('setChatroomLoader', true);
      }

      try {
        firestore
          .collection('chatrooms')
          .where(`${uid}.member`, '==', true)
          .onSnapshot(async (snapshot) => {
            let deleteKeys = await db.chatrooms.orderBy('id').keys();

            if (snapshot.size) {
              const chatroomsData = [];

              snapshot.docs.map((room) => {
                let chatroom = room.data();
                const ownerId = chatroom[uid]['enableChat'];
                const getId = chatroom[uid]['partnerId'];
                const partnerId = chatroom[getId]['enableChat'];
                if (ownerId == 0 || partnerId == 0) {
                  chatroom = {};
                  return null;
                }
                chatroom['id'] = room.id;

                chatroom['lastChatDate'] =
                  chatroom['lastChat'] == null
                    ? chatroom['last_chat_date'] ?? 0
                    : chatroom['lastChat'][uid]['date'] ?? 0;

                deleteKeys = deleteKeys.filter((key) => key !== room.id);

                if (
                  state.selectedRoom &&
                  chatroom['id'] == state.selectedRoom['id']
                ) {
                  commit('setCurrentRoom', chatroom);
                }

                const profile = chatroom[getId]['profile'];

                chatroom['isSupport'] = profile?.isSupport || false;
                chatroom['ToUserName'] = profile?.name || '';

                // db.chatrooms.put(chatroom, chatroom['id']);
                // keys.push(chatroom['id']);
                chatroomsData.push(chatroom);
              });
              commit('setChatrooms', chatroomsData);
              if (intialKeys.length == 0) {
                commit('setChatroomLoader', false);
              }
              db.chatrooms.bulkPut(chatroomsData);
            }
            db.chatrooms.bulkDelete(deleteKeys);
          });
      } catch (e) {
        console.log(e);
      }
    },
    fetchChatroomAllMessages({ rootState }) {
      try {
        firestore
          .collection('chats')
          // .where('chatRoomId', '==', `${chatroom['id']}`)
          .where(`deletedFor.${rootState.user.uid}`, '==', false)
          .onSnapshot(async (snapshot) => {
            console.log(snapshot.size);
            var deleteKeys = await db.chats.orderBy('id').keys();
            if (snapshot.size) {
              const chatsData = snapshot.docs?.map((chat) => {
                const data = chat.data();
                data['id'] = chat.id;

                deleteKeys = deleteKeys.filter((key) => key !== chat.id);

                const keyStr = data['chatRoomId'];
                const ivStr = data['id'];
                const encrptData = data['data'];

                data['payload'] = decrypt(keyStr, ivStr, encrptData);
                if (data['payload']['medialType'] == 'video') {
                  data['payload']['medialType'] = getMediaType(
                    data['payload']['medialUrl']
                  );
                }
                if (data['payload']['repliedToChatId'] != null) {
                  if (
                    data['payload']['repliedToChatContent'].startsWith(
                      'http'
                    ) &&
                    data['payload']['repliedToChatContent'].includes(
                      'firebasestorage.googleapis.com'
                    )
                  ) {
                    data['payload']['repliedToChatMedialType'] = getMediaType(
                      data['payload']['repliedToChatContent']
                    );
                  }
                }

                if (data['payload']['medialType'] == '') {
                  data['message'] = data['payload']['message'];
                }
                // db.chats.put(data, data['id']);
                // keys.push(data['id']);
                return data;
              });
              db.chats.bulkPut(chatsData);
            }

            db.chats.bulkDelete(deleteKeys);
          });
      } catch (e) {
        console.log(e);
      }
    },
    async deleteMessage(
      { commit, state },
      {
        chatData,
        isMe,
        showDialog = true,
        previousChat = null,
        isLastIndex = false,
        batch = null,
      }
    ) {
      console.log(isMe, chatData, showDialog, previousChat, isLastIndex);
      try {
        return new Promise(async (resolve) => {
          const doc = firestore.collection('chats').doc(chatData.id);

          const snapshot = await doc.get();
          const data = snapshot.data();
          if (data) {
            var deletedForLength = 0;

            Object.keys(data['deletedFor']).forEach((key) => {
              if (data['deletedFor'][key] === false) {
                deletedForLength++;
              }
            });

            console.log('deletedForLength ', deletedForLength);
            // deletedForLength <= 1 => Other user is already deleted So i am only left to delete ducument.
            // isMe == false => delete document for all users.

            if (deletedForLength <= 1 || isMe == false) {
              if (batch) {
                batch.delete(snapshot.ref);
              } else {
                snapshot.ref.delete();
              }
              const medialUrl = chatData['payload']['medialUrl'];
              if (medialUrl) {
                const httpsReference = storage.refFromURL(medialUrl);
                console.log(httpsReference.fullPath);
                storage.ref(`${httpsReference.fullPath}`).delete();
              }
              if (isLastIndex == true && !batch) {
                const chatroomDoc = firestore
                  .collection('chatrooms')
                  .doc(data['chatRoomId']);
                const previousChatId = previousChat
                  ? previousChat.id ?? ''
                  : '';
                const previousChatDate = previousChat
                  ? previousChat.date ?? 0
                  : 0;
                await chatroomDoc.update({
                  last_chat_id: previousChatId,
                  last_chat_date: previousChatDate,
                  lastChat: {
                    [`${data['fromUID']}`]: {
                      id: previousChatId,
                      date: previousChatDate,
                    },
                    [`${data['toUID']}`]: {
                      id: previousChatId,
                      date: previousChatDate,
                    },
                  },
                });
              }
            } else {
              //delete for single user.
              const map = {
                [`deletedFor.${state.sessionUID}`]: true,
              };
              if (batch) {
                batch.update(snapshot.ref, map);
              } else {
                await doc.update(map);
              }
              if (isLastIndex == true && !batch) {
                const chatroomDoc = firestore
                  .collection('chatrooms')
                  .doc(data['chatRoomId']);
                await chatroomDoc.update({
                  [`lastChat.${state.sessionUID}.id`]: previousChat
                    ? previousChat.id ?? ''
                    : '',
                  [`lastChat.${state.sessionUID}.date`]: previousChat
                    ? previousChat.date ?? 0
                    : 0,
                });
              }
            }
            if (showDialog == true) {
              commit('setIsDeleteChat', false);
              commit('setSnackBartext', 'Message deleted');
            }
            resolve(true);
          }
        });
      } catch (e) {
        console.log(`send message: ${e}`);
        return '';
      }
    },
    async addSupportChatRoom({ rootState, commit }) {
      commit('setShowSupportAddDialog', false);

      const userCollection = firestore.collection('users');
      const docRef = userCollection.where('isSupport', '==', true);
      const docs = await docRef.get();
      let supportUidMap;
      let supportUid;
      // console.log(docs.size);
      if (docs.size > 0) {
        supportUidMap = docs.docs[0].data();
        supportUid = docs.docs[0].id;
      }

      const userUid = rootState.user.uid;
      const userUidMap = rootState.user;

      if (supportUid && supportUidMap && userUid && userUidMap) {
        try {
          const room = {
            resetFlag: false,
            isGroup: false,
            groupDetails: {
              name: '',
              avatar: '',
            },
            uids: [userUid, supportUid],
            [userUid]: {
              profile: {
                imgUrl: userUidMap['imgUrl'],
                isSupport: false,
                name: userUidMap['name'],
                uid: userUidMap['uid'],
              },
              member: true,
              online: false,
              readReceipts: true,
              partnerId: supportUid,
              files: [],
              isBlocked: false,
              resetTime: '',
              resetCode: '',
              enableChat: 1,
            },
            [supportUid]: {
              profile: {
                imgUrl: supportUidMap['imgUrl'],
                isSupport: true,
                name: supportUidMap['name'],
                uid: supportUidMap['uid'],
              },
              member: true,
              online: false,
              readReceipts: true,
              partnerId: userUid,
              files: [],
              isBlocked: false,
              resetTime: '',
              resetCode: '',
              enableChat: 1,
            },
          };
          const res = await firestore.collection('chatrooms').add(room);
          const obj = (await res.get()).data();
        } catch (e) {
          console.log(`add Support ChatRoom ${e}`);
        }
      }
    },
    async deleteChatroom({ dispatch, state, commit }) {
      const chatRoomId = state.selectedRoom['id'];
      try {
        const docs = await firestore
          .collection('chats')
          .where('chatRoomId', '==', chatRoomId)
          .get();

        var batch = firestore.batch();

        if (docs.docs.length > 0) {
          const tasks = docs.docs.map(async (chat) => {
            const data = chat.data();
            data['id'] = chat.id;

            const keyStr = data['chatRoomId'];
            const ivStr = data['id'];
            const encrptData = data['data'];

            data['payload'] = decrypt(keyStr, ivStr, encrptData);

            await dispatch('deleteMessage', {
              chatData: data,
              isMe: false,
              showDialog: false,
              previousChat: null,
              isLastIndex: true,
              batch: batch,
            });
            return true;
          });
          await Promise.all(tasks);
        }

        batch.commit();
        await firestore.collection('chatrooms').doc(chatRoomId).delete();
        if (state.selectedRoom['id'] == chatRoomId) {
          commit('setCurrentRoom', null);
          commit('setMessages', []);
        }
        return 'deleted chatroom and all messages';
      } catch (e) {
        console.log(`delete chatroom & messages : ${e}`);
        return 'Failed to deleted chatroom & messages';
      }
    },
    async toChatRoomPasscode({}, { chatRoomId, uid, pass }) {
      console.log(chatRoomId, uid, pass);
      try {
        const ref = firestore.collection('chatrooms').doc(chatRoomId);
        const refDoc = await ref.get();
        const refDocData = refDoc.data();
        refDocData[uid]['passcode'] = pass;
        refDocData[uid]['resetCode'] = '';
        refDocData[uid]['resetTime'] = 0;
        await ref.set(refDocData);
      } catch (e) {
        console.log(`toChatRoomPasscode ${e}`);
      }
    },
    async blockAllowUser({ commit, state }, otherUserUid) {
      commit('setIsBlockDialog', false);
      try {
        const ref = firestore
          .collection('chatrooms')
          .doc(state.selectedRoom['id']);
        const refDoc = await ref.get();
        let refDocData = refDoc.data();
        refDocData[otherUserUid]['isBlocked'] = !(
          state.selectedRoom[otherUserUid]?.isBlocked ?? false
        );
        await ref.set(refDocData);
        commit(
          'setSnackBartext',
          `User ${
            !(state.selectedRoom[otherUserUid]?.isBlocked ?? false) ? '' : 'un'
          }blocked`
        );
      } catch (e) {
        console.log(`blockUnblockUser ${e}`);
      }
    },
    async toggleReadReceiptsUser({ commit, state }) {
      commit('setChatDetailSettings', false);
      const flag = !state.selectedRoom[state.sessionUID]['readReceipts'];
      try {
        const ref = firestore
          .collection('chatrooms')
          .doc(state.selectedRoom['id']);
        const refDoc = await ref.get();
        const refDocData = refDoc.data();
        refDocData[state.sessionUID]['readReceipts'] = flag;
        refDocData[state.sessionUID]['online'] = flag;
        await ref.set(refDocData);
      } catch (e) {
        console.log(`toggleReadReceiptsUser ${e}`);
      }
    },
    async deleteAllMsg({ state, dispatch, commit }) {
      try {
        commit('setMessages', []);
        const chatroom = state.selectedRoom;
        const docs = await firestore
          .collection('chats')
          .where('chatRoomId', '==', chatroom['id'])
          .get();

        if (docs.docs.length == 0) {
          return 'You have not sent any messages yet';
        }

        var batch = firestore.batch();

        const tasks = docs.docs.map(async (chat) => {
          const data = chat.data();
          data['id'] = chat.id;

          const keyStr = data['chatRoomId'];
          const ivStr = data['id'];
          const encrptData = data['data'];

          data['payload'] = decrypt(keyStr, ivStr, encrptData);

          await dispatch('deleteMessage', {
            chatData: data,
            isMe: true,
            showDialog: false,
            previousChat: null,
            isLastIndex: true,
            batch: batch,
          });
          return true;
        });
        await Promise.all(tasks);
        batch.commit();
        const chatroomDoc = firestore
          .collection('chatrooms')
          .doc(chatroom['id']);
        const previousChatId = '';
        const previousChatDate = 0;
        const partnerId = chatroom[state.sessionUID]['partnerId'];
        console.log(partnerId);

        await chatroomDoc.update({
          last_chat_id: previousChatId,
          last_chat_date: previousChatDate,
          lastChat: {
            [`${state.sessionUID}`]: {
              id: previousChatId,
              date: previousChatDate,
            },
            [`${partnerId}`]: {
              id: previousChatId,
              date: previousChatDate,
            },
          },
        });
        return 'All messages cleared';
      } catch (e) {
        console.log('delete all', e);
        return 'Failed to clear messages';
      }
    },
    async setOnlineStatus({}, { chatRoomId, uid, status }) {
      return new Promise(async (resolve) => {
        const doc = await firestore
          .collection('chatrooms')
          .doc(chatRoomId)
          .get();
        if (doc.exists) {
          const data = doc.data();
          if (data[`${uid}`]['readReceipts'] == true) {
            doc.ref.set(
              {
                [uid]: { online: status },
              },
              { merge: true }
            );
          }
        }
        resolve(true);
      });
    },
    async setSeenStatus({}, { uid, chatRoomId }) {
      try {
        return new Promise(async (resolve) => {
          // console.log('uid: ' + uid + 'chatRoomId: ' + chatRoomId);
          const doc = await firestore
            .collection('chats')
            .where('chatRoomId', '==', `${chatRoomId}`)
            .where('fromUID', '==', `${uid}`)
            .where('seen', '==', false)
            .get();
          // console.log('doc.size: ' + doc.size);
          if (doc.size > 0) {
            var batch = firestore.batch();
            doc.docs.forEach((d) => {
              if (d.exists) batch.update(d.ref, { seen: true });
            });
            batch.commit();
          }
          resolve(true);
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
