import Vue from 'vue';
import VueRouter from 'vue-router';
import firebase from 'firebase/app';
import 'firebase/auth';

Vue.use(VueRouter);
const routes = [
    {
        path: '/',
        // path: '/web',
        name: 'Chats',
            component: () => import(/* webpackChunkName: "Chats" */ '../chats'),
        meta: {
        authRequired: true,
        },
        
    },
    // {
    //     path: '/web',
    //     name: 'WebLogin',
    //         component: () => import(/* webpackChunkName: "Chats" */ '../chats/Login.vue'),
    // }
    
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
});

// router.beforeEach(async (to, from, next) => {
//     const uid = sessionStorage.getItem('uid');
    
//     if (to.matched.some((record) => record.meta.authRequired)) {  
          
//         if (firebase.auth().currentUser || uid) {
//           next({
//             path: '/chats'
//           });
//         } else {
//              next();
//       }
//     } else {
//         console.log(uid);
//          next({
//             path: '/chats'
//           });
//       }
// });
export default router;
