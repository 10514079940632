<template>
  <v-app v-if="getCurrentUser">
    <v-main v-if="getRole">
      <navbar
        @getActive="getActive($event)"
        :drawer="drawer"
        @change="onToggle"
      />
      <v-container fluid v-if="getRole">
        <router-view />
      </v-container>
      <v-container v-else>
        <div class="row">
          <div class="col not-auth">You are not an Authorized user</div>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import 'firebase/firestore';
import Navbar from '../components/Navbar';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

export default {
  name: 'Dashboard',
  data() {
    return {
      drawer: true,
      isActive: 'users',
    };
  },
  computed: {
    ...mapGetters(['getSelectedUser', 'getCurrentUser', 'getRole']),
    ...mapState({
      user: (state) => state.user,
    }),
  },
  methods: {
    ...mapActions(['fetchUsers', 'fetchCurrentUser']),
    ...mapMutations(['setRole']),
    getActive(linktype) {
      this.isActive = linktype;
    },
    onToggle(value) {},
  },
  components: {
    Navbar,
  },
};
</script>
<style lang="scss">
.not-auth {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
