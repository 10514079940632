<template>
  <v-app-bar app color="primary" elevation="2" class="fixed-top">
    <v-container fluid class="d-flex align-center justify-between">
      <div class="d-flex align-center justify-center">
        <v-app-bar-nav-icon v-if="false" @click.stop="$emit('toggle', !drawer)"
          style="color: white"></v-app-bar-nav-icon>
        <v-btn to="/" plain>
          <v-img alt="Visicardia Logo" class="shrink mr-2" contain src="@/assets/images/visicardia.png"
            transition="scale-transition" width="40" />
        </v-btn>
      </div>
      <span class="mx-4"></span>
      <v-btn v-if="getRole.id == superadmin" depressed elevation="1" color="primary" @click="goToDoctor">Doctor</v-btn>
      <span class="mx-4"></span>
      <v-spacer />
      <!-- <v-text-field
        v-model="searchText"
        label="Search"
        solo
        height="40"
        hide-details
        @change="setSearchText"
      /> -->
      <v-spacer />
      <v-btn v-if="getRole.id == superadmin || getRole.id == hradmin" depressed elevation="1" color="primary"
        @click="goToCompany">{{ getRole.id == hradmin ? 'My Company' : 'Company' }}</v-btn>
      <v-menu v-model="menu" :close-on-content-click="false" :max-width="240" offset-y v-if="currentUser">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="white" dark v-bind="attrs" :text="true" v-on="on">
            Hi {{ currentUser.name }}
            <v-icon class="px-2">{{
          !menu ? 'fa-angle-down' : 'fa-angle-up'
        }}</v-icon>
          </v-btn>
        </template>

        <v-card :max-width="240">
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <img :src="currentUser.imgUrl" alt="John" />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title :title="currentUser.name">{{
          currentUser.name && currentUser.name !== null
            ? currentUser.name.toUpperCase()
            : currentUser.name
        }}</v-list-item-title>
                <v-list-item-subtitle :title="currentUser.email">{{
          currentUser.email
        }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="getRole" :title="getRole.role">{{
          getRole.role
        }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list>
            <v-btn :text="true" block @click="logout">
              <v-icon class="px-2">fa-logout</v-icon>
              <span>Logout</span>
            </v-btn>
          </v-list>
        </v-card>
      </v-menu>
    </v-container>
  </v-app-bar>
</template>
<script>
import firebase from 'firebase/app';
import 'firebase/firestore';
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'Navbar',
  props: {
    drawer: Boolean,
  },
  data() {
    return {
      menu: false,
      loading: false,
      searchText: '',
      isActive: true,
    };
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.user,
      admin: (state) => state.admin,
      superadmin: (state) => state.superadmin,
      hradmin: (state) => state.hradmin,
    }),
    ...mapGetters(['getRole', 'getRoles']),
  },
  methods: {
    ...mapMutations(['setSearchText', 'setRole']),
    users() {
      this.$emit('getActive', 'users');
    },
    cards() {
      this.$emit('getActive', 'cards');
    },
    async logout() {
      try {
        await firebase.auth().signOut();
        this.$router.replace('/login');
      } catch (e) {
    console.log('error', e)
      }
    },
    onSearch() { },
    goToCompany() {
      if (this.getRole.id == this.hradmin) {
        this.$router.push(`/edit-company/${this.currentUser.companyId}`);
      } else {
        this.$router.push('/company');
      }
    },
    goToDoctor() {
      this.$router.push('/doctor');
    },
  },
};
</script>
