import Vue from 'vue';
import App from './App.vue';
import './plugins/fireinit';
// import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './assets/main.scss';
import '@fortawesome/fontawesome-free/css/all.css';

Vue.config.productionTip = false;

import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';
import VueCountryCode from 'vue-country-code-select';
import Index from './router/index';
import Admin from './router/admin.js';
import Web from './router/web';

const host = window.location.host;
// const host = window.location.pathname;
const router = () => {
  let routes;
  switch (host) {
    case 'admin.visicardia.com':
    case 'dev.admin.visicardia.com':
      routes = Admin;
      break;
    case 'web.visicardia.com':
    case 'dev.web.visicardia.com':
      routes = Web;
      break;
    case 'localhost:8080':
      routes = Admin;
      break;
    default:
      routes = Index;
      break;
  }
  return routes;
};

Vue.use(VueCountryCode);
Vue.use(VueGoodTablePlugin);

new Vue({
  router: router(),
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
