import Vue from "vue";
import Vuex from "vuex";
import firebase from "firebase/app";
import "firebase/auth";
import colorJson from "../../../assets/colors.json";
import chat from "./chat.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    chat,
  },
  state: {
    user: null,
    users: [],
    doctors: [],
    companies: [],
    companiesType: [],
    roles: [],
    isRole: {},
    uid: null,
    selectedUser: [],
    searchText: "",
    collectionResult: [],
    docDetails: null,
    admin: 1,
    hradmin: 2,
    superadmin: 3,
    colorJson,
    selectedNode: null,
  },
  mutations: {
    setSearchText(state, text) {
      state.searchText = text;
    },
    setUsers(state, users) {
      state.users = users;
    },
    setDoctors(state, doctors) {
      state.doctors = doctors;
    },
    setCompanies(state, companies) {
      state.companies = companies;
    },
    setCompaniesType(state, companiesType) {
      state.companiesType = companiesType;
    },
    setCards(state, cards) {
      state.cards = cards;
    },
    setReceived(state, tableData) {
      state.received = tableData;
    },
    setPending(state, tableData) {
      state.pending = tableData;
    },
    setShared(state, tableData) {
      state.shared = tableData;
    },
    setPermissions(state, tableData) {
      state.permissions = tableData;
    },
    setRequests(state, tableData) {
      state.request = tableData;
    },
    setInvites(state, tableData) {
      state.invite = tableData;
    },
    setSelectedUser(state, row) {
      state.selectedUser = row;
    },
    setDrawer(state, drawer) {
      state.drawer = drawer;
    },
    setRole(state, role) {
      state.isRole = role;
    },
    setCollectionResults(state, result) {
      state.collectionResult = result;
    },
    setDocDetails(state, details) {
      state.docDetails = details;
    },
    setCurrentUser(state, user) {
      state.user = user;
    },
    setRoles(state, roles) {
      state.roles = roles;
    },
    setSelectedNode(state, node) {
      state.selectedNode = node;
    },
  },
  getters: {
    getCollectionResults(state) {
      return state.collectionResult;
    },
    getDrawer(state) {
      return state.drawer;
    },
    getDoctors(state) {
      return state.doctors;
    },
    getCompanies(state) {
      return state.companies;
    },
    getCompaniesType(state) {
      return state.companiesType;
    },
    getRole(state) {
      return state.isRole;
    },
    getSearchText(state) {
      return state.searchText;
    },
    getSelectedUser(state) {
      return state.selectedUser;
    },
    getDocDetails(state) {
      return state.docDetails;
    },
    getUsers(state) {
      return state.users;
    },
    getCards(state) {
      return state.cards;
    },
    getDocDetails(state) {
      return state.docDetails;
    },
    getCurrentUser(state) {
      return state.user;
    },
    getRoles(state) {
      return state.roles;
    },
  },
  actions: {
    async fetchCollectionResults({ commit }, { name, condition, value }) {
      let collRef = firebase.firestore();
      switch (name) {
        case "cards":
          collRef = collRef.collection("cards");
          break;
        case "received":
          collRef = collRef.collection("received");
          break;
        case "shared":
          collRef = collRef.collection("received");
          break;
        case "pending":
          collRef = collRef.collection("received");
          collRef = collRef.where("pending", "==", true);
          break;
        case "permissions":
          collRef = collRef.collection("permissions");
          break;
        case "requests":
          collRef = collRef.collection("requests");
          break;
        case "chatrooms":
          collRef = collRef.collection("chatrooms");
          break;
        case "chats":
          collRef = collRef.collection("chats");
          break;
        default:
          collRef = collRef.collection(name);
      }
      try {
        return new Promise(async (resolve) => {
          let docsRef;
          if (condition) {
            collRef = collRef.where(condition, "==", value);
            collRef = collRef.get();
            docsRef = await collRef;
          } else {
            docsRef = await collRef.get();
          }
          const results = docsRef.docs.map((doc) => {
            const data = doc.data();
            data["id"] = doc.id;
            return data;
          });

          commit("setCollectionResults", results);
          resolve(true);
        });
      } catch (e) {
        console.log(e);
      }
    },
    async fetchChatrooms() {
      return;
      const chatrooms = await firebase
        .firestore()
        .collection("chatrooms")
        .where(
          "P0EQIj8Gf9WcGHEsL0wzQ10wnCB2.profile.uid",
          "==",
          "P0EQIj8Gf9WcGHEsL0wzQ10wnCB2"
        )
        .get()
        .then((roomDocs) => {
          roomDocs.docs.forEach(async (roomDoc) => {
            if (roomDoc.exists) {
              const user = roomDoc.data();
              if (
                user.P0EQIj8Gf9WcGHEsL0wzQ10wnCB2.enableChat > 0 &&
                user.P0EQIj8Gf9WcGHEsL0wzQ10wnCB2.recdCardId.length > 0 &&
                user[user.P0EQIj8Gf9WcGHEsL0wzQ10wnCB2.partnerId].enableChat ==
                  0
              ) {
                console.log(user.P0EQIj8Gf9WcGHEsL0wzQ10wnCB2.partnerId);
                const partner = `'${user.P0EQIj8Gf9WcGHEsL0wzQ10wnCB2.partnerId}.enableChat':1`;
                console.log(partner);
                // await firebase
                //   .firestore()
                //   .collection('chatrooms').doc(roomDoc.id).update({
                //    partner
                //   });
              }
            }
          });
        });
    },
    async fetchDocDetails({ commit }, { collection, doc }) {
      switch (collection) {
        case "cards":
          collection = "cards";
          break;
        case "received":
          collection = "received";
          break;
        case "shared":
          collection = "received";
          break;
        case "pending":
          collection = "received";
          collection = collection.where("pending", "==", true);
          break;
        case "permissions":
          collection = "permissions";
          break;
        case "requests":
          collection = "requests";
          break;
        default:
          collection = collection;
      }
      try {
        return new Promise(async (resolve) => {
          const docDetails = await firebase
            .firestore()
            .collection(collection)
            .doc(doc)
            .get();
          commit("setDocDetails", docDetails.data());
          resolve(true);
        });
      } catch (e) {
        console.log(e);
      }
    },
    async fetchUsers({ commit, state }) {
      try {
        return new Promise(async (resolve) => {
          const collection = firebase.firestore().collection("users");

          var allUsers;
          if (state.isRole && state.isRole.id == state.hradmin) {
            try {
              allUsers = await collection
                .where("companyId", "==", state.user.companyId)
                .get();
            } catch (e) {
              console.log(e);
            }
          } else {
            allUsers = await collection.get();
          }

          const userData = allUsers?.docs?.map((user) => {
            const data = user.data();
            data["uid"] = user.id;
            return data;
          });
          userData.sort((a, b) => {
            return (
              new Date(a.createDate * 1000) < new Date(b.createDate * 1000)
            );
          });
          // console.log(userData);
          commit("setUsers", userData ?? []);
          resolve(true);
        });
      } catch (e) {
        console.log(e);
      }
    },
    async fetchCurrentUser({ commit, dispatch, state }, uid) {
      try {
        return new Promise(async (resolve) => {
          firebase
            .firestore()
            .collection("users")
            .doc(uid)
            .onSnapshot(async (currentUser) => {
              if (currentUser.exists) {
                const userData = currentUser.data();
                if (userData.disabled) {
                  commit("setCurrentUser", null);
                  commit("setRole", null);
                  resolve(null);
                  return;
                }
                if (userData) {
                  await dispatch("fetchRoles");
                  const curRole = state.roles.filter(
                    (r) => +r.id == userData.roleId
                  );
                  commit("setCurrentUser", userData);
                  if (curRole.length) {
                    const role = curRole[0];
                    commit("setRole", role);
                    dispatch("fetchUsers");
                  }
                  resolve(userData);
                }
              } else {
                commit("setRole", null);
              }
              resolve(null);
            });
        });
      } catch (e) {
        console.log(e);
      }
    },
    async fetchUser({}, uid) {
      if (!uid) {
        return;
      }
      try {
        return new Promise(async (resolve) => {
          const doc = await firebase
            .firestore()
            .collection("users")
            .doc(uid)
            .get();

          if (doc.exists) {
            const data = doc.data();
            resolve(data);
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
    async fetchCard({}, cardId) {
      try {
        return new Promise(async (resolve) => {
          const card = await firebase
            .firestore()
            .collection("cards")
            .doc(cardId)
            .get();
          if (card.exists) {
            const cardData = card.data();
            resolve(cardData);
          } else {
            resolve(null);
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
    async fetchRoles({ commit }) {
      try {
        return new Promise(async (resolve) => {
          const collection = firebase.firestore().collection("roles");

          const allRoles = await collection.get();
          const batch = firebase.firestore().batch();
          const roles = allRoles.docs.map((role) => {
            const data = role.data();
            data["id"] = role.id;
            batch.update(collection.doc(role.id), data);
            return data;
          });
          batch.commit(); //.then(() => console.log('update id in roles'));
          commit("setRoles", roles);
          resolve(true);
        });
      } catch (e) {
        console.log(e);
      }
    },
    async updateUser({}, { uid, updateMap }) {
      console.log(uid, updateMap);
      if (!uid) {
        return;
      }
      if (!updateMap) {
        return;
      }
      try {
        return new Promise(async (resolve) => {
          const collection = firebase.firestore().collection("users");
          await collection.doc(uid).update(updateMap, { merge: true });
          resolve(true);
        });
      } catch (e) {
        console.log(e);
      }
    },
    async fetchDoctors({ commit, state }) {
      try {
        return new Promise(async (resolve) => {
          const collection = firebase.firestore().collection("doctor");
          if (
            state.isRole &&
            (state.isRole.id == state.admin ||
              state.isRole.id == state.superadmin)
          ) {
            const allDoctors = await collection.get();

            const doctorData = allDoctors?.docs?.map((user) => {
              const data = user.data();
              data["id"] = user.id;
              return data;
            });
            commit("setDoctors", doctorData ?? []);
            resolve(true);
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
    async fetchCompanies({ commit, state }) {
      try {
        return new Promise(async (resolve) => {
          const collection = firebase.firestore().collection("company");
          if (
            state.isRole &&
            (state.isRole.id == state.admin ||
              state.isRole.id == state.superadmin)
          ) {
            const allCompanies = await collection.get();

            const companyData = allCompanies?.docs?.map((user) => {
              const data = user.data();
              data["id"] = user.id;
              return data;
            });
            commit("setCompanies", companyData ?? []);
            resolve(true);
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
    async fetchCompany({}, id) {
      try {
        return new Promise(async (resolve) => {
          const collection = firebase.firestore().collection("company");
          const companyDoc = await collection.doc(id).get();
          if (companyDoc.exists) {
            resolve(companyDoc.data());
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
    async fetchCards({}, companyId) {
      try {
        if (!companyId) {
          return;
        }
        return new Promise(async (resolve) => {
          const collection = firebase.firestore().collection("cards");
          const cardsDoc = await collection
            .where("companyId", "==", companyId)
            .get();
          if (cardsDoc.size) {
            const allData = cardsDoc.docs.map((e) => {
              const data = e.data();
              data["id"] = e.id;
              return data;
            });
            resolve(allData);
          }
          resolve([]);
        });
      } catch (e) {
        console.log(e);
      }
    },
    async fetchCompaniesType({ commit, state }) {
      try {
        return new Promise(async (resolve) => {
          const collection = firebase.firestore().collection("companyType");
          const allCompaniesType = await collection.get();

          const companyTypeData = allCompaniesType?.docs?.map((user) => {
            const data = user.data();
            data["id"] = user.id;
            return data;
          });
          commit("setCompaniesType", companyTypeData ?? []);
          resolve(true);
        });
      } catch (e) {
        console.log(e);
      }
    },
    async fetchCompaniesType({ commit, state }) {
      try {
        return new Promise(async (resolve) => {
          const collection = firebase.firestore().collection("companyType");
          const allCompaniesType = await collection.get();

          const companyTypeData = allCompaniesType?.docs?.map((user) => {
            const data = user.data();
            data["id"] = user.id;
            return data;
          });
          commit("setCompaniesType", companyTypeData ?? []);
          resolve(true);
        });
      } catch (e) {
        console.log(e);
      }
    },
    async addDoctor({ dispatch }, { doctor, id }) {
      console.log(doctor);
      try {
        return new Promise(async (resolve) => {
          const collection = firebase.firestore().collection("doctor");
          if (id != null) {
            await collection
              .doc(id)
              .update(doctor)
              .catch((e) => console.log(e));
          } else {
            doctor["created"] = firebase.firestore.FieldValue.serverTimestamp();
            const v = await collection.add(doctor).catch((e) => console.log(e));
            const newValue = await collection
              .doc(v.id)
              .update({ id: v.id })
              .catch((e) => console.log(e));
          }
          dispatch("fetchDoctors");
          resolve(true);
        });
      } catch (e) {
        console.log(e);
      }
    },
    async addCompany({ dispatch }, { company, id }) {
      try {
        return new Promise(async (resolve) => {
          const collection = firebase.firestore().collection("company");
          if (id != null) {
            await collection
              .doc(id)
              .update(company)
              .catch((e) => console.log(e));
            resolve(id);
          } else {
            company["created"] =
              firebase.firestore.FieldValue.serverTimestamp();
            const v = await collection
              .add(company)
              .catch((e) => console.log(e));
            const newValue = await collection
              .doc(v.id)
              .update({ id: v.id })
              .catch((e) => console.log(e));
            resolve(v.id);
          }
          dispatch("fetchCompanies");
        });
      } catch (e) {
        console.log(e);
      }
    },
    async addCompanyType({ dispatch }, { companyType, id = null }) {
      try {
        return new Promise(async (resolve) => {
          const collection = firebase.firestore().collection("companyType");
          if (id != null) {
            await collection
              .doc(id)
              .update(companyType)
              .catch((e) => console.log(e));
          } else {
            companyType["created"] =
              firebase.firestore.FieldValue.serverTimestamp();
            const v = await collection
              .add(companyType)
              .catch((e) => console.log(e));
            const newValue = await collection
              .doc(v.id)
              .update({ id: v.id })
              .catch((e) => console.log(e));
          }
          dispatch("fetchCompaniesType");
          resolve(true);
        });
      } catch (e) {
        console.log(e);
      }
    },
    async addCard({ dispatch }, { cardData, id }) {
      try {
        return new Promise(async (resolve) => {
          const collection = firebase.firestore().collection("cards");
          if (id != null) {
            await collection
              .doc(id)
              .update(cardData)
              .catch((e) => console.log(e));
            resolve(id);
          } else {
            const v = await collection
              .add(cardData)
              .catch((e) => console.log(e));
            resolve(v.id);
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
    async fetchAllRecords({ commit }, link) {
      var tableName = link.value;
      var table = "";
      try {
        return new Promise(async (resolve) => {
          if (link.name == "Pending") {
            table = await firebase
              .firestore()
              .collection(tableName.toLowerCase())
              .where("pending", "==", true)
              .get();
          } else {
            table = await firebase
              .firestore()
              .collection(tableName.toLowerCase())
              .get();
          }

          const tableData = table.docs.map((tbl) => {
            return {
              ...tbl.data(),
              id: tbl.id,
            };
          });
          commit(`set${tableName}`, tableData);
          resolve(true);
        });
      } catch (e) {
        console.log(e);
      }
    },
    async addShareCardOnReceiveCard({}, { receivedCard, sharedCard }) {
      console.log(receivedCard, sharedCard);
      try {
        return new Promise(async (resolve) => {
          const collection = firebase.firestore().collection("received");

          const sharedDoc = await collection
            .where("from.uid", "==", receivedCard.to.uid)
            .where("to.uid", "==", receivedCard.from.uid)
            .get();

          if (sharedDoc.size == 0) {
            const data = {
              cardId: sharedCard.id,
              from: {
                uid: receivedCard.to.uid,
                name: receivedCard.to.name,
                email: receivedCard.to.email,
                phone: receivedCard.to.phone,
              },
              to: {
                uid: receivedCard.from.uid,
                name: receivedCard.from.name,
                email: receivedCard.from.email,
                phone: receivedCard.from.phone,
              },
              pending: true,
              notes: "",
              acceptOnCardId: [receivedCard.cardId],
              createTime: new Date().toISOString(),
            };
            const requestData = {
              acceptOnCardId: receivedCard.cardId,
              cardId: sharedCard.id,
              createTime: new Date().toISOString(),
              dateTime: new Date().toISOString(),
              description: "New Card Request",
              from: receivedCard.to.uid,
              fromName: receivedCard.to.name,
              read: false,
              to: receivedCard.from.uid,
              toName: receivedCard.from.name,
            };
            await firebase.firestore().collection("requests").add(requestData);
            await collection.add(data);
            await collection.doc(receivedCard.id).update({
              acceptOnCardId: firebase.firestore.FieldValue.arrayUnion(
                sharedCard.id
              ),
            });
            resolve("Request sent successfully");
          } else {
            resolve("Already added");
          }
        });
      } catch (e) {
        console.log(e);
        resolve(`${e}`);
      }
    },
  },
});
